/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

// This is an empty "theme" that is needed to be able to configure
// storybook with arbitrary number of user supplied themes
export const Theme = {
  name: undefined
};

export const ThemeProvider = {};
