/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import { makeCarbonTheme } from '@exo/frontend-theme-base-carbon-theme';
import carbonVariables from './CarbonVars';

/* @ts-ignore */
import Logo from './assets/imarket_RGB.svg';

const base = {
  byComponent: {
    insurance_Title: {},
    core_masthead_navDesktop: {
      background: carbonVariables.interactive01,
      color: 'white',
      __action: {
        padding: '0.85rem',
        $hover: {
          background: carbonVariables.interactive01
        }
      }
    },
    core_masthead_header: {
      $$large: {
        padding: '0rem 0.2rem'
      },
      __logo: {
        maxWidth: '6.688rem',
        maxHeight: '2.063rem',
        $$large: {
          maxWidth: '8.688rem',
          maxHeight: '3.063rem'
        }
      },
      color: '#fff',
      background: '#714E9D'
    },
    core_footer: {
      $$large: {
        padding: '0rem 2.813rem'
      },
      padding: '0rem 2.813rem'
    },
    base_button: {
      fontSize: '0.875rem',
      fontWeight: '400'
    },
    core_menu: {
      menuBgColor: '#fff',
      headerBackground: '#fff',
      activeBackground: '#dcdcdc',
      activeText: '#473F4A',
      headerColor: '#714E9D'
    }
  } as EXOComponentStyles,

  // TODO: Find a better solution for assets ... and rename tempImg
  static: {
    tempImg: '/static/images/homepage/hero-1.jpg',
    Logo
  },
  breakpoints: {
    small: '1024px',
    medium: '1024px',
    large: '1584px',
    xLarge: '1584px',
    max: '1584px'
  }
};

const Theme: any = makeCarbonTheme(
  'Polaris Theme',
  '0.4',
  carbonVariables,
  {
    heading: "'Noto Sans', sans-serif",
    body: "'Noto Sans', sans-serif"
  },
  base as any,
  `html, body {
    font-family: 'Noto Sans', sans-serif;
  }
  .bx--toast-notification--error {
    margin-top: calc(var(--top) + 1rem) !important;
  }
  .oneRemPadding {
    padding: 1rem !important;
  }
  .marginTopNegative3R {
    margin-top: -3rem;
  }
  .marginTop1R {
    margin-top: 1rem;
  }
  .marginBottom1R {
    margin-bottom: 1rem;
  }
  .marginLeft1R {
    margin-left: 1rem;
  }

  .noPadding {
    padding: 0 !important;
  }

  .noPadding-top {
    padding-top: 0rem !important;
  }
  
  .large-padding-top {
    padding-top: 2rem !important;
  }
  
  .noPadding-left-right {
    padding-right: 0rem !important;
    padding-left: 0rem !important;
  }

  .showEllipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  div {
    scrollbar-color: #714e9d white;
    scrollbar-width: auto;
  }

  ::-webkit-scrollbar {
    width: 0.75rem;
    height: 0.75rem;
    background: white;
  }
  ::-webkit-scrollbar-thumb {
  background-color: #714e9d;
  border-radius: 0.625rem;
  }

  .bx--pagination {
    border-top: 1px solid var(--cds-ui-01, #e0e0e0);
    background-color: var(--cds-ui-02, #f4f4f4);
  }
  .bx--pagination .bx--select-input {
    background-color: var(--cds-ui-02, #f4f4f4);
  }
  .bx--pagination .bx--select__item-count .bx--select-input {
    border-right: 0.0625rem solid var(--cds-ui-01, #e0e0e0);
  }
  .bx--pagination .bx--select-input:hover {
    background: var(--cds-hover-ui, #e5e5e5);
  }
  .bx--pagination .bx--select__arrow {
    fill: #161616;
  }
  .bx--pagination__button, .bx--btn--ghost.bx--pagination__button, .bx--pagination .bx--select__page-number .bx--select-input {
    border-color: var(--cds-ui-01, #e0e0e0)
  }
  .blocked {
    pointer-events: none;
    opacity: 0.4;
  }
  .react-tooltip {
    border-radius: 0rem !important;
    background-color: #714e9d !important;
    color: #ffffff !important;
    font-size: 0.875rem !important;
    letter-spacing: 0.01rem;
    line-height: 1.5rem;
    opacity: 1 !important;
    padding: 0.4rem 0.6rem !important;
  }

  .long-list {
    display: flex;
    align-items: flex-start;
  }

  .long-list--submit {
    border: none;
    margin-left: 1rem;
    margin-top: 2.2rem;
  }

  .no-caption {
    margin-top: 1.3rem!important;
  }

  .long-list--submit_multi {
    border: none;
    margin-left: 1rem;
    margin-bottom: 1rem;
  }

  .longlist-modal .bx--modal-container {
    width: 680px!important;
    margin-top:3rem!important;
    max-height: calc(100vh - 5rem)!important;
  }

  .longlist-modal .bx--modal-content {
    overflow: auto!important;
  }

  .longlist-modal .bx--data-table tbody {
    max-height: unset!important;
  }
  #announcements{
    display: none;
  }
  `
);

export default Theme;
