/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import React from 'react';
import baseLoadable from '@loadable/component';

export const loadable = (fn, opts = {}) => {
  return baseLoadable(fn, {
    fallback: <div></div>,
    resolveComponent: loaded => loaded.default,
    ...opts
  });
};
