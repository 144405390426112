/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import { storage } from './storage';

const SESSION_CONTEXT_KEY = 'sessionContext';

export const sessionStorage = {
  get: (): EXOSession | undefined => {
    const s = storage.getItem(SESSION_CONTEXT_KEY);
    if (!s) return undefined;

    const entry = JSON.parse(s);
    if (!entry) return undefined;

    return entry as EXOSession;
  },

  set: (session: EXOSession) => {
    storage.setItem(SESSION_CONTEXT_KEY, JSON.stringify(session));
  }
};
