/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import { setContext } from '@apollo/client/link/context';
import { sessionStorage } from '@exo/frontend-common-polaris-session-context';

export const authLink = setContext((_, { headers }) => {
  const session = sessionStorage.get();
  const token = session?.token;

  if (token && process.env.ENABLE_AUTH) {
    return {
      headers: {
        ...headers, // de pus in functie de process.env
        authorization: `Bearer ${token}` // TODO add this back when graphql server is ready
        // username
      }
    };
  }

  return {
    headers: {
      ...headers
    }
  };
});
