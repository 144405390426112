/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import { from, ApolloClient } from '@apollo/client';
import { cacheFactory } from './cache/cacheFactory';
import { namedLink } from './links/namedLink';
import { intlLink } from './links/intlLink';
import { authLink } from './links/authLink';
import { loggingLink } from './links/loggingLink';
import { uniqueRequestIdLink } from './links/uniqueRequestIdLink';
import { smartBatchLink } from './links/smartBatchLink';
import { createUploadLink } from 'apollo-upload-client';

declare global {
  interface Window {
    __APOLLO_STATE__: any;
  }
}

//@ts-ignore
export const apolloClientFactory = ({ batch, endpoint }: { batch?: boolean; endpoint: string }) => {
  const isSSR = typeof window === 'undefined';
  const isDevMode = process.env.NODE_ENV === 'development';

  const links: any[] = [];

  const uploadLink = createUploadLink({ credentials: 'include', uri: endpoint });

  if (isDevMode) {
    links.push(namedLink(endpoint));
    if (process.env.DEVMODE_GQL_LOGGING?.toUpperCase() === 'TRUE') {
      links.push(loggingLink);
    }
  }

  links.push(intlLink);
  links.push(uniqueRequestIdLink);
  links.push(authLink);
  if (process.env.APOLLO_SMART_BATCH?.toUpperCase() === 'TRUE') {
    links.push(smartBatchLink);
  }
  links.push(uploadLink);

  const link = from(links);

  return new ApolloClient({
    link,
    cache: cacheFactory().restore(
      // eslint-disable-next-line no-underscore-dangle
      isSSR ? {} : window.__APOLLO_STATE__
    ),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first'
      }
    }
  });
};
