/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import React, { useContext, useState } from 'react';
import { sessionStorage } from '../storage/sessionStorage';

export const PolarisSessionContext = React.createContext<SessionContextType | undefined>(undefined);

type UserType = 'NONE' | 'GUEST' | 'USER' | string;

declare global {
  interface EXOSession {
    token?: string;
    tokenExpiryTime?: number;
    type?: UserType;
    roles: string[];
    firstName?: string;
    lastName?: string;
    email?: string;
    storeName?: string;
    storeId?: string;
    storeDistance?: string;
    country?: string;
    language?: string;
    username?: string;
    currency?: string;
    verifier?: string;
    name?: string;
    roleName?: string;
    blockedUser?: boolean;
  }
}

type SessionContextType = EXOSession & {
  set: (newData: Partial<EXOSession>) => void;
  get: () => Promise<string | undefined>;
  hasRole: (role: string) => boolean;
  replace: (newData: EXOSession) => Promise<void>;
};

declare global {
  interface Window {
    Cypress?: any;
    sessionContext?: EXOSession;
  }
}

export const usePolarisSessionContext = () => {
  const session = useContext(PolarisSessionContext);
  return session!;
};

const initialState = () => sessionStorage.get();

export const PolarisSessionContextProvider = ({ children, state }: Props) => {
  const [context, setContext] = useState<EXOSession>(
    state ??
      initialState() ?? {
        roles: [],
        blockedUser: false
      }
  );

  const set = (newContext: EXOSession) => {
    setContext(newContext);
    sessionStorage.set(newContext);
  };

  // Ensure session context is not nested
  const sessionContext = useContext(PolarisSessionContext);
  if (sessionContext) {
    return children;
  }

  const value = {
    ...context,

    get: (): Promise<string | undefined> => {
      if (context.token) return Promise.resolve(context.token);
      return Promise.resolve(undefined);
    },

    set: (attrs: Partial<EXOSession>) => {
      set({ ...context, ...attrs });
    },

    hasRole: (role: string) => {
      if (!context.roles) return false;

      return context.roles.includes(role);
    },

    replace: async (newAttrs?: EXOSession) => {
      const newContext = newAttrs ?? {
        type: 'NONE',
        roles: []
      };

      set(newContext);
    }
  };

  if (process.env.NODE_ENV !== 'production' && typeof window !== 'undefined' && window.Cypress) {
    window.sessionContext = value;
  }

  return <PolarisSessionContext.Provider value={value}>{children}</PolarisSessionContext.Provider>;
};

type Props = {
  children: any;
  state?: EXOSession;
};
