/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import React, { useContext, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

export const NotificationContext = React.createContext<any>(null);

export const useNotificationContext = () => useContext(NotificationContext);

export const NotificationContextProvider = ({ children }) => {
  const [context, setContext] = useState<NotificationType[]>([]);
  const value = {
    ...context,

    getNotifications: () => context,

    createNotification: (n: Omit<NotificationType, 'id'>) => {
      const newNotice = {
        ...n,
        id: uuidv4()
      };
      setContext([...context, newNotice]);
    },

    removeNotification: (id: string) => {
      const newNotifications = context.filter(n => n.id !== id && n);
      setContext(newNotifications);
    }
  };
  return <NotificationContext.Provider value={value}>{children}</NotificationContext.Provider>;
};

export type NotificationType = {
  close?: string;
  id: string;
  kind?: 'error' | 'info' | 'success' | 'warning';
  title: string;
  subtitle?: string;
};
