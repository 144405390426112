/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import React, { useContext, useState, useEffect } from 'react';
import { ApolloProvider, getApolloContext, ServerError, ServerParseError } from '@apollo/client';
import { ErrorResponse } from '@apollo/client/link/error';
import { GraphQLError } from 'graphql';
import { Notification } from '@exo/frontend-components-base';
import { useAppShellContext } from './AppShellContext';

type NetworkError = Error | ServerError | ServerParseError;

// TODO: We should rename this as it is actually part of the "error boundary"
// TODO: Maybe move to navigation or some sort of chrome feature
export const AppShellApolloProvider = ({ children }) => {
  const [currentGQLError, setCurrentGQLError] = useState<readonly GraphQLError[] | undefined>();
  const [currentNetworkError, setCurrentNetworkError] = useState<NetworkError | undefined>();
  const { client } = useContext(getApolloContext());
  const config = useAppShellContext();

  const handleGQLError = (e: readonly GraphQLError[]) => {
    if (!currentGQLError) setCurrentGQLError(e);
  };
  const handleNetworkError = (e: NetworkError) => {
    if (!currentGQLError) setCurrentNetworkError(e);
  };

  const handleClear = () => {
    setCurrentGQLError(undefined);
    setCurrentNetworkError(undefined);
  };

  useEffect(() => {
    const handleEvent = (event: CustomEvent | Event) => {
      const error: ErrorResponse = (event as CustomEvent).detail;
      if (error.graphQLErrors) {
        if ((event as CustomEvent).detail.graphQLErrors[0].handled) return;
        handleGQLError(error.graphQLErrors);
      }
      if (error.networkError) {
        handleNetworkError(error.networkError);
      }
    };
    document.addEventListener('gql-error', handleEvent, false);

    return () => {
      document.removeEventListener('gql-error', handleEvent, false);
    };
  });

  if (client) {
    return <>{children}</>;
  }

  // TODO: Ideally we should move this to the NotificationContext somehow
  return (
    <>
      {currentGQLError && (
        <Notification
          display="floating"
          type="error"
          title="GQL Error"
          subtitle={currentGQLError[0]?.message}
          onClose={() => handleClear()}
          description={`Path: ${currentGQLError[0]?.path?.length && currentGQLError[0].path[0]}`}
        />
      )}
      {currentNetworkError && (
        <Notification
          display="floating"
          type="error"
          title="GQL Error"
          subtitle={currentNetworkError[0]?.message}
          onClose={() => handleClear()}
          description="This is a GQL Error"
        />
      )}
      <ApolloProvider client={config!.client()}>{children}</ApolloProvider>
    </>
  );
};
