/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import { ApplicationConfig } from '@exo/frontend-common-app-shell';
import axios from 'axios';
import { loadable } from './loadable';

export const cmsConfig: ApplicationConfig['featureConfig']['content'] = {
  components: [
    ...(process.env.PROVIDERS_CONTENT_PROVIDER?.includes('provider-aem')
      ? [
          {
            name: 'cmsSpot',
            component: loadable(() => import('@exo/frontend-content-provider-aem'), {
              resolveComponent: loaded => loaded.CmsSpotComponent
            }),
            aem: {
              minimumProps: [],
              name: 'components/structure/cmsSpot'
            }
          },
          {
            name: 'text',
            component: loadable(
              () => import('@exo/frontend-content-provider-aem-core-components'),
              {
                resolveComponent: loaded => loaded.Text
              }
            ),
            aem: {
              minimumProps: ['text'],
              name: 'components/core/text',
              emptyLabel: 'Uninitialized Text'
            }
          },
          {
            name: 'image',
            component: loadable(
              () => import('@exo/frontend-content-provider-aem-core-components'),
              {
                resolveComponent: loaded => loaded.Image
              }
            ),
            aem: {
              minimumProps: ['src'],
              name: 'components/core/image',
              emptyLabel: 'Uninitialized image'
            }
          },
          {
            name: 'title',
            component: loadable(
              () => import('@exo/frontend-content-provider-aem-core-components'),
              {
                resolveComponent: loaded => loaded.Title
              }
            ),
            aem: {
              minimumProps: ['text'],
              name: 'components/core/title',
              emptyLabel: 'Uninitialized title'
            }
          },
          {
            name: 'tabs',
            component: loadable(
              () => import('@exo/frontend-content-provider-aem-core-components'),
              {
                resolveComponent: loaded => loaded.Tabs
              }
            ),
            aem: {
              minimumProps: [],
              name: 'components/core/tabs'
            }
          },
          {
            name: 'teaser',
            component: loadable(
              () => import('@exo/frontend-content-provider-aem-core-components'),
              {
                resolveComponent: loaded => loaded.Teaser
              }
            ),
            aem: {
              minimumProps: ['title'],
              name: 'components/core/teaser',
              emptyLabel: 'Uninitialized teaser'
            }
          },
          {
            name: 'separator',
            component: loadable(
              () => import('@exo/frontend-content-provider-aem-core-components'),
              {
                resolveComponent: loaded => loaded.Separator
              }
            ),
            aem: {
              minimumProps: [],
              name: 'components/core/separator'
            }
          },
          {
            name: 'formContainer',
            component: loadable(
              () => import('@exo/frontend-content-provider-aem-core-components'),
              {
                resolveComponent: loaded => loaded.FormContainer
              }
            ),
            aem: {
              minimumProps: [],
              name: 'components/form/container'
            }
          },
          {
            name: 'formButton',
            component: loadable(
              () => import('@exo/frontend-content-provider-aem-core-components'),
              {
                resolveComponent: loaded => loaded.FormButton
              }
            ),
            aem: {
              minimumProps: [],
              name: 'components/form/button'
            }
          },
          {
            name: 'formHidden',
            component: loadable(
              () => import('@exo/frontend-content-provider-aem-core-components'),
              {
                resolveComponent: loaded => loaded.FormHidden
              }
            ),
            aem: {
              minimumProps: [],
              name: 'components/form/hidden'
            }
          },
          {
            name: 'formOptions',
            component: loadable(
              () => import('@exo/frontend-content-provider-aem-core-components'),
              {
                resolveComponent: loaded => loaded.FormOptions
              }
            ),
            aem: {
              minimumProps: [],
              name: 'components/form/options'
            }
          },
          {
            name: 'formText',
            component: loadable(
              () => import('@exo/frontend-content-provider-aem-core-components'),
              {
                resolveComponent: loaded => loaded.FormText
              }
            ),
            aem: {
              minimumProps: [],
              name: 'components/form/text'
            }
          }
        ]
      : []),
    {
      name: 'video',
      component: loadable(() => import('@exo/frontend-components-content'), {
        resolveComponent: loaded => loaded.Video
      }),
      aem: {
        minimumProps: ['videoURL'],
        name: 'components/content/video',
        emptyLabel: 'Uninitialized Video'
      }
    },
    {
      name: 'hero',
      component: loadable(() => import('@exo/frontend-components-content'), {
        resolveComponent: loaded => loaded.Hero
      }),
      aem: {
        minimumProps: ['image', 'subtitle', 'title', 'text', 'ctaText'],
        name: 'components/content/hero',
        emptyLabel: 'Uninitialized Hero'
      }
    },
    {
      name: 'heroCarousel',
      component: loadable(() => import('@exo/frontend-components-content'), {
        resolveComponent: loaded => loaded.HeroCarousel
      }),
      aem: {
        minimumProps: ['items'],
        name: 'components/content/heroCarousel',
        emptyLabel: 'Uninitialized Hero Carousel'
      }
    },
    {
      name: 'contentCard',
      component: loadable(() => import('@exo/frontend-components-content'), {
        resolveComponent: loaded => loaded.ContentCard
      }),
      aem: {
        minimumProps: ['title', 'img'],
        name: 'components/content/contentCard',
        emptyLabel: 'Uninitialized Content Card'
      }
    },
    {
      name: 'horizontalContentCard',
      component: loadable(() => import('@exo/frontend-components-content'), {
        resolveComponent: loaded => loaded.HorizontalContentCard
      }),
      aem: {
        minimumProps: ['title', 'img'],
        name: 'components/content/horizontalContentCard',
        emptyLabel: 'Uninitialized Horizontal Content Card'
      }
    },
    {
      name: 'featuredItem',
      component: loadable(() => import('@exo/frontend-components-content'), {
        resolveComponent: loaded => loaded.FeaturedItem
      }),
      aem: {
        minimumProps: ['title', 'text', 'image'],
        name: 'components/content/featuredItem',
        emptyLabel: 'Uninitialized Featured Item'
      }
    },
    {
      name: 'departmentSelector',
      component: loadable(() => import('@exo/frontend-components-content'), {
        resolveComponent: loaded => loaded.DepartmentSelector
      }),
      aem: {
        minimumProps: ['departments'],
        name: 'components/content/departmentSelector',
        emptyLabel: 'Uninitialized Department Selector'
      }
    },
    {
      name: 'videoWithText',
      component: loadable(() => import('@exo/frontend-components-content'), {
        resolveComponent: loaded => loaded.VideoWithText
      }),
      aem: {
        minimumProps: ['videoURL', 'title', 'text'],
        name: 'components/content/videoWithText',
        emptyLabel: 'Uninitialized Video with Text'
      }
    },
    {
      name: 'imageGrid',
      component: loadable(() => import('@exo/frontend-components-content'), {
        resolveComponent: loaded => loaded.ImageGrid
      }),
      aem: {
        minimumProps: ['images'],
        name: 'components/content/imageGrid',
        emptyLabel: 'Uninitialized Image Grid'
      }
    }
  ],
  aem: {
    path: process.env.AEM_ROOT_PATH!,
    siteName: process.env.AEM_APP!,
    apiHost: process.env.AEM_HOST,
    gridCss: 'https://adobe-marketing-cloud.github.io/aem-responsivegrid/css/aem-grid-12.css',
    loadingDepth: 2
  },
  brxm: {
    debug: process.env.NODE_ENV === 'development',
    endpoint: process.env.BR_HOST,
    httpClient: axios,
    endpointQueryParameter: 'endpoint'
  }
};

export const rewriteConfig: ApplicationConfig['rewrite'] = process.env.PROVIDERS_CONTENT_PROVIDER?.includes(
  'provider-aem'
)
  ? [
      {
        type: 'legacy',
        when: `^${process.env.AEM_ROOT_PATH}/(.*)\\.html$`,
        inbound: {
          match: `^${process.env.AEM_ROOT_PATH}/(.*)\\.html$`,
          rewrite: '/$1'
        },
        outbound: {
          match: '^(.*(?!\\.html$))$',
          rewrite: `${process.env.AEM_ROOT_PATH}$1.html`
        }
      },
      {
        type: 'legacy',
        when: '^/conf/[^/]+/settings/wcm/templates/.*html$',
        inbound: {
          match: '^/conf/(.*).html$',
          rewrite: '/content/templates/$1'
        }
      }
    ]
  : [ ];
