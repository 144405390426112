/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import {
  ApplicationConfig,
  AppShellApolloProvider,
  AppShellContextProvider
} from '@exo/frontend-common-app-shell';
import { ThemeProvider } from '@exo/frontend-common-theme-proxy';
import { NotificationContextProvider } from '@exo/frontend-common-notification';
import { EventContextProvider } from '@exo/frontend-features-events-logic';
import React from 'react';
import { DevContextProvider } from '@exo/frontend-features-dev-toolbar-logic';
import { PolarisSessionContextProvider } from '@exo/frontend-common-polaris-session-context';

export const AppWrapper = ({ config, children }) => {
  return (
    <AppShellContextProvider config={config}>
      <AppShellApolloProvider>
        <DevContextProvider config={config as ApplicationConfig}>
          <NotificationContextProvider>
            <PolarisSessionContextProvider>
              <EventContextProvider configuration={config}>
                <ThemeProvider>{children}</ThemeProvider>
              </EventContextProvider>
            </PolarisSessionContextProvider>
          </NotificationContextProvider>
        </DevContextProvider>
      </AppShellApolloProvider>
    </AppShellContextProvider>
  );
};
