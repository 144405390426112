/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import React from 'react';
import { InlineNotification, ToastNotification } from 'carbon-components-react';

export const Notification = ({
  id,
  title,
  subtitle,
  description,
  type,
  display,
  onClose,
  isClosable=true,
  className
}: Props) => {
  if (display === 'floating') {
    return (
      <ToastNotification
        id={id}
        title={title}
        subtitle={subtitle}
        caption={description}
        onCloseButtonClick={onClose}
        kind={type}
        className={className}
        hideCloseButton={!isClosable}
      />
    );
  } else {
    return (
      <InlineNotification
        id={id}
        title={title}
        subtitle={subtitle}
        onCloseButtonClick={onClose}
        kind={type ?? 'info'}
        className={className}
        hideCloseButton={!isClosable}
      />
    );
  }
};

type Props = {
  id?: string;
  title: string;
  subtitle?: string;
  description?: string;
  type?: 'error' | 'warning' | 'info' | 'success';
  display?: 'inline' | 'floating';
  isClosable?: boolean;
  onClose?: () => void;
  className?: string;
};
